.carousel-root {
  width: 64% !important;
  margin-left: 50% !important;
  /* margin-left: 800px !important;
  margin-right: 100px !important; */
  margin-top: -3% !important;
  }
  
  .carousel .slide {
    /* background: #fff !important; */
    color: black;
    height: 100%;
  }
  
  .carousel .slide img {
    width: 139px !important;
    border-radius: 50%;
  }
  
  .myCarousel {
    /* background: #fafafa; */
    margin-top: -6%;
    width: 54%;
    margin-left: auto;
    margin-right: auto;
    padding-top: 6%;
    padding-bottom: 8%;
    padding-left: 5%;
    padding-right: 5%;
    border: 1px solid #ddd;
    height: 286px;
  }
  
  /* .carousel .control-dots {
    padding-left: 5px !important;
    outline: 0;
    bottom: 5% !important;
  } */
  
  .carousel .control-dots {
    position:unset !important;
  }
  .myCarousel h3 {
    color: #222;
    font-weight: 100;
    letter-spacing: 0.2px;
    margin-bottom: 4px;
    font-weight: 600;
    text-transform: uppercase;
    font-size: 17px;
  }
  
  .myCarousel h4 {
    text-transform: uppercase;
    margin-top: 0;
    padding-top: 0;
    font-weight: 500;
    color: #787878;
    font-size: 14px;
  }
  
  .myCarousel p {
    font-weight: 100 !important;
    line-height: 29px !important;
    color: #222;
    font-size: 15px;
    font-family: sans-serif;
    max-height: 67px;
  }
  
  .myCarousel p:before {
    content: "“";
    color: #aaa;
    font-size: 26px;
    font-family: monospace;
    font-weight: 100;
  }
  
  .myCarousel p:after {
    content: "”";
    color: #aaa;
    font-size: 26px;
    font-family: monospace;
    font-weight: 100;
    line-height: 0;
  }
  
  /* .carousel .control-dots .dot {
    box-shadow: none !important;
    background: #454545 !important;
    outline: 0;
  } */
  
  .carousel.carousel-slider .control-arrow {
   
    background: #000 !important;
    height: 50px !important;
    position: absolute;
    top: 35% !important;
  }
  .carousel.carousel-slider .control-arrow {  
    color:var(--secondary) !important; 
    margin-top: 192px !important; 
    background: none !important;   
}

.carousel .control-next.control-arrow {
    right: 18px !important;
}
  .App {
    text-align: center;
  }
  .carousel .control-arrow, .carousel.carousel-slider .control-arrow {
     opacity: 1 !important;
  }
  .carousel.carousel-slider .control-arrow {   
    top: 9% !important;
}


.testimonials{
    padding: 100px 0;
    background: var(--primary) url("../../assets/testimonial.jpg") top left no-repeat;
    background-size: contain; 
    overflow: hidden;   
}

.testimonial-item{
  text-align: left;
  width:70%;
}
.testimonial-item p{
  font-size: 1.1rem;
  font-weight: 400;
  color: #54595F;
  line-height: 1.5;
  margin-top: 22px;
}
.testimonial-item span{
  padding-right: 15px;
  color:var(--secondary);
}
.testimonial-item .client-desc{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 0 !important;
  width: 54%;    
  padding:0 3% !important;
  border: 0 !important;
  height: 76px !important;
}
.testimonial-item .client-desc .left-sec img{
  width: 60px !important;
  height: 60px;
  border-radius: 0% !important;
  background-color: var(--secondary);
  padding: .25rem;
  display: block;
}
.testimonial-item .client-desc .right-sec{
  display: flex;
  flex-direction: column;
  width: fit-content;
  margin-right: 42px;
}
.testimonial-item .client-desc .right-sec h3{
  text-transform: capitalize;
  font-size: 1rem;
}
.testimonial-item .client-desc .right-sec span{
  text-transform: uppercase;
  font-size: 0.8rem;
  color:#54595F;
  margin-top: 5px;
}  
@media screen and (max-width:768px){
  .testimonials{
    background-size: cover;
  }
  .testimonials {
    padding: 100px 22px;
  }
  .testimonial-item {    
    width: 100%;
}
.testimonial-item .client-desc {
    width: 41% !important;
    background: none !important;
}
}
  @media only screen and (max-width: 934px) {
    .carousel-root {
      outline: 0;
      width: 93% !important;
      margin: auto !important;
    }
  
    .carousel.carousel-slider .control-arrow {
      display: none !important;
    }
    .myCarousel {
      background: #fafafa;
      margin-top: -9%;
      width: 88%;
      margin-left: auto;
      margin-right: auto;
      padding-top: 8%;
      padding-bottom: 12.5%;
      padding-left: 5%;
      padding-right: 5%;
      border: 1px solid #ddd;
      height: 269px;
    }
  
    .carousel .slide img {
      width: 24% !important;
      border-radius: 50%;
    }
  }
  @media screen and (max-width:992px){
    .testimonial-item .client-desc {
      width: 61% !important;
    }
  }