body{
  margin: 0;
  font-family: sans-serif;
  font-size: 1rem;
  font-weight: 400;
  color: #54595F;
  background-color: #fff;
  overflow: auto;
}
*{
  box-sizing: border-box;
}
:root {
  --primary: #FFE468;
  --secondary: #8CC641;
  --light: #F2F2F2;
  --dark: #272630;
  --bs-font-sans-serif: "Roboto", sans-serif;
    --bs-font-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
    --bs-gradient: linear-gradient(180deg, rgba(255,255,255,0.15), rgba(255,255,255,0));
}
a{
  text-decoration: none;
}
ul{
  margin: 0;
  padding: 0;
  list-style-type: none;
}
h1,h2,h3,h4,h5,h6{
  margin-top: 0;
  margin-bottom: .5rem;
  font-family: "Roboto Condensed",sans-serif;
  font-weight: 700;
  line-height: 1.2;
  color: #272630;
  transform: scaleY(1.2);
}
p{
  margin-top: 0;
  margin-bottom: 1rem;
}