.painter{
  padding:100px 100px; 
  display: flex;     
}
.left-p{    
    background:var(--primary) url("../../assets/about_bg.jpg") center center no-repeat;
    background-size: contain;    
    width:60%;
    height: fit-content;
    padding: 3rem;
    align-self: center;  
    position: relative;  
    margin-right: -90px;
    z-index: 1;  
    text-align: left;  
} 
.left-p p{
    margin: 30px 0;
    margin-bottom:48px;
    font-family: var(--bs-font-sans-serif);
    font-size: 0.8rem;
    line-height: 1.25rem;
}
.left-p a{
    background-color: var(--secondary);
    color:#fff;
    padding:1rem 3rem;    
    border-radius: 50rem;
    font-size: 0.8rem;
    font-weight: 700;
    transition: .5s;
}
.left-p a:hover{
    box-shadow: 0 8px 6px -6px #555555;
}
.right-p{
    width:53.5%;    
}
.right-p img{   
    max-width: 100%;
    min-height: 30rem;     
}
@media screen and (max-width:768px) {
    .painter{
        flex-direction: column;
        padding: 100px 30px;    
        text-align: left;
    }
    .left-p{      
        width: 100%;    
        margin:auto;
    }
    .left-p h1{
        font-size: 1.6rem;
    }
    .right-p{
        width:100%;    
    }
    }
@media screen and (max-width:992px) {
    .left-p h1{
        font-size: 1.8rem;
    }
}