.professional{
    padding: 100px 100px;
    margin-top: 50px;   
}
.professional h1{
    text-align: center;
    width: 42%;
    display: block;
    margin: 0 auto 74px;    
}
.professional h1::after{
    content: "";
    border-bottom: 1px solid var(--primary);
    width: 122px;
    display: block;    
    margin: auto;
    margin-top: 10px;
}
.services{     
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap:15px;   
    text-align: center;     
}
.service-item{
    margin: 30px;
    background-color: var(--primary);
    flex: 300px;
    padding-bottom: 41px;
}   
 .service-icon{
    position: relative;
    top: -55px;
    background: #ffffff;    
    display: inline-flex;
    justify-content: center;
    align-items: center;
    width: 90px;
    height: 90px;
    padding: 10px;
    transform: rotate(-45deg);    
}
.service-icon div{
    background-color: var(--primary);
    color: var(--secondary);
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: .5s;
}
.service-icon span{
    font-size: 1.5rem;
    transform: rotate(45deg);
    font-weight: 900;
    transition: 0.5s;
} 

.service-item a{
    color:var(--secondary);    
    font-size: 0.75rem;
    font-weight: 700;
    position: relative;
    bottom: -30px;
    opacity: 0;
    transition: .5s;
}
.service-item:hover a{
    bottom: 0;
    opacity: 1;
}
.service-item a span{
    padding-left: 8px;
    vertical-align: middle; 
}
.service-item{
    background: url("../../assets/service.jpg") top center no-repeat;
    background-size: cover;
    transition: .5s;
}
.service-item:hover{
    background: url("../../assets/service.jpg") bottom center no-repeat;
    background-size: cover;
}
.service-item:hover .service-icon div{
    background-color: var(--secondary);
    color:var(--primary);
}
@media screen and (max-width:768px) {
    .professional {
        padding: 59px 3px;
        margin-top: 0px;
    }
    .professional h1 {
    font-size: 1.6rem;
    white-space: nowrap;
    width: fit-content;
    }
}
@media screen and (max-width:992px) {
    .professional h1 {      
        width: 53%;
    }
}