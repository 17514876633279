.choose-us{
    padding:0 100px; 
    display: flex;    
    margin-top: -105px; 
  }
.left-c{
    width:53.5%;    
}
.left-c img{   
    max-width: 100%;
    min-height: 30rem;     
}
.right-c{   
      background:var(--primary) url("../../assets/about_bg.jpg") center center no-repeat;
      background-size: contain;    
      width:60%;
      height: fit-content;
      padding: 3rem;
      align-self: center;  
      position: relative;  
      margin-left: -90px;
      z-index: 1;  
      text-align: left;  
  } 
.right-c p{
      margin: 30px 0;
      margin-bottom:48px;
      font-family: var(--bs-font-sans-serif);
      font-size: 0.8rem;
      line-height: 1.25rem;
  }
.right-c a{
      background-color: var(--secondary);
      color:#fff;
      padding:1rem 3rem;    
      border-radius: 50rem;
      font-size: 0.8rem;
      font-weight: 700;
      transition: .5s;
  }
.right-c a:hover{
      box-shadow: 0 8px 6px -6px #555555;
  }

  @media screen and (max-width:768px){
    .choose-us{
      flex-direction: column;
      padding: 100px 30px;
      text-align: left;
    }
    .left-c{
      width:100%;    
  }
  .left-c img {    
    min-height: 0;
}
  .right-c{ 
    width: 100%;
    margin: auto;  
  }
  .right-c h1{
    font-size: 1.6rem;
}
  }