.teammembers{
    padding:150px 100px;   
}
.teammembers h1{
    text-align: center;
}
.teammembers h1::after{
    content: "";
    border-bottom: 1px solid var(--primary);
    width: 13%;
    display: block;
    margin: 14px auto;   
}
.teams{
    display: flex;
    justify-content: space-between;
    gap:20px;
    margin-top: 54px;
}
img{
    max-width: 100%;
    display: block;
    height: auto;
}
.team{
    position: relative;
    margin-bottom: 45px;
}
.team:hover .team-text{
    height: 100%;
    bottom: 0;
}
.team-text{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: var(--primary) url("../../assets/team.jpg") bottom center no-repeat;
    background-size: cover;
    height: 90px;
    padding: 0 30px;
    position: absolute;
    left: 45px;
    right: 45px;
    bottom: -45px;
    z-index: 1;
    transition: .5s;
}
.socialicons{
    position: absolute;
    transition: .1s;    
    opacity: 0;
}
.team:hover .socialicons{
    transition-delay: .3s;
    opacity: 1;
}
.socialicons span{
    background-color: var(--secondary);
    color: #fff;
    width:50px;
    height: 50px;
    margin:10px;
    border-radius: 50%;
    padding: 10px;
    line-height: 10px;
}
.socialicons span:hover{
    box-shadow: 0 8px 6px -6px #555555;
}
 .teamdesc{
    text-align: center;
    margin-top: auto;
    margin-bottom: 1rem;
} 
.teamdesc h4{
    font-size: 0.9rem;
    font-weight: 800;
    transform: scaleY(1.3);
}
.teamdesc span{
    text-transform: uppercase;
    font-size: 0.7rem;
}

@media screen and (max-width:768px){
    .teammembers {
        padding: 92px 30px;
    }
    .teams{
        flex-direction: column;
    }
    .team {       
        margin: 0 auto 45px;
    }
}
@media screen and (max-width:992px){
    .teammembers {
        padding: 150px 32px;
    }
}