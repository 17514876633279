.blogs{
    padding:150px 100px;
}
.blogs h1:after{
    content: "";
    border-bottom: 1px solid var(--primary);
    width: 13%;
    display: block;
    margin: 14px auto; 
}
.blogitem{
    display: flex;
    justify-content: space-between;
    gap:20px;
    margin-top: 70px;    
}
img{
    max-width: 100%;
    height: auto;
    display: block;
}
.calender{
    display: flex;
    align-items: center;
    background-color: var(--primary);
}
.calender .left-sec-ca{
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-color: var(--secondary);
    color:#fff;
    width: 70px;
    height: 100px;
    text-align: center;
    margin-top: -1.5rem;
    margin-right: 1.5rem;    
}
.calender .left-sec-ca span{
    color:var(--primary);
    font-size:0.8rem;
}
.calender .left-sec-ca p,.calender .left-sec-ca small{
    font-family:sans-serif;
    font-size: 0.8rem;
    font-weight: 400;
    line-height: 1.5;
    margin: 0;
}
.calender .right-sec-ca{
    overflow: hidden;
  text-overflow: ellipsis;
  /* white-space: nowrap; */
}
.calender .right-sec-ca a{
  margin-right: 1.5rem;  
  font-size: 1rem;
  color: #54595F;
  font-family: "Roboto Condensed",sans-serif;
  font-weight: 700;
  line-height: 1.1;  
}
.calender .right-sec-ca a:hover{
    color:var(--primary);  
}
.user-sec{
  display: flex;
  justify-content: space-between;
  border-top: 1px solid var(--secondary);
  background-color: var(--primary);
  padding: 1rem;
}
.user-sec .left-sec-u,.user-sec .right-sec-u{
  display: flex;
  align-items: center;
} 
.user-sec small{
    font-size: 0.75rem;
} 
.user-sec .left-sec-u img{
    width: 30px;
    height: 30px;
    border-radius: 50%;
    margin-right: .5rem;
    vertical-align: middle;
}  
.user-sec .left-sec-u small{
    text-transform: uppercase;    
}
.user-sec .right-sec-u small{
    margin-left: 1rem;
}
.user-sec .right-sec-u span{
    color: var(--secondary);
    margin-right: 0.3rem;
    font-weight: 900;
}

@media screen and (max-width:768px) {
    .blogs {
        padding: 150px 30px;
    }
    .blogitem{
        flex-direction: column;
    }
}
@media screen and (max-width:992px){
    .blogs {
        padding: 150px 32px;
    }
    .user-sec {        
        padding: 1rem 9px;
    }
    .calender .right-sec-ca a {
        margin-right: 1rem;
        font-size: 0.9rem;        
    }
    .calender .left-sec-ca {       
        width: 105px;        
    }
}